<template>
    <b-row class="mt-1">
        <b-col class="d-flex flex-row" cols="5">
            <label for="from">From:</label>
            <b-form-input size="sm" id="from" type="number" v-bind:value="value.from" v-on:input="updateValue($event.target.value)"></b-form-input>
        </b-col>
        <b-col class="d-flex flex-row" cols="5">
            <label for="to">To:</label>
            <b-form-input size="sm" id="to" type="number" v-bind:value="value.to" v-on:input="updateValue($event.target.value)"></b-form-input>
        </b-col>
        <b-col cols="2">
            <b-form-input size="sm" type="color" v-bind:value="value.color" v-on:input="updateValue($event.target.value)"></b-form-input>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'plotBand',
        props: ['value'],
        data() {
            return {}
        },
        methods: {
            updateValue: function (value) {
                console.log(value)
                this.$emit('update:plotBands[index]', value)
            }
        }
    }
</script>

<style scoped>
</style>